import Link from '@/components/base/elements/Link/Link';
import Typography from '@/components/base/elements/Typography/Typography';
import Image, {type ImageProps} from '@/components/base/elements/Image/Image';
import {twMerge} from '@/stylesheets/twMerge';

export interface CustomerReviewProps {
  image: ImageProps;
  title: string;
  url: string;
  urlText: string;
  className?: string;
}

const CustomerReview = ({
  image,
  title,
  url,
  urlText,
  className,
}: CustomerReviewProps) => (
  <div
    className={twMerge(
      'flex flex-col md:flex-row items-center md:items-start md:justify-start rounded-xl border b-[#ffffff33] px-8 pt-6 pb-16 md:p-8 shadow-[0px_0px_1px_0px_rgba(66,71,76,0.48),_0px_4px_8px_0px_rgba(66,71,76,0.06),_0px_8px_48px_0px_rgba(238,238,238,0.60)]',
      className,
    )}
  >
    <Link href={url} className="mb-2 md:mr-6" arrow={false}>
      <Image {...image} loading="lazy" />
    </Link>
    <div className="flex flex-col items-center md:items-start">
      <Typography
        size="t3"
        as="h2"
        className="text-center md:text-left text-[34px] md:text-[32px] font-bold mb-2 lg:mb-6"
      >
        {title}
      </Typography>
      <Link
        className="block text-center md:text-left text-black"
        size="small"
        href={url}
      >
        {urlText}
      </Link>
    </div>
  </div>
);

export default CustomerReview;
