import SectionHeader from '@/components/base/sections/SectionHeader/SectionHeader';
import HeadingGroup, {
  type HeadingGroupProps,
} from '@/components/base/modules/HeadingGroup/HeadingGroup';
import CustomerReview, {
  type CustomerReviewProps,
} from '@/components/plus/modules/CustomerReview/CustomerReview';
import CardGrid from '@/components/base/layouts/CardGrid/CardGrid';
import Stat from '@/components/plus/modules/Stats/Stat';
import type {StatProps} from '@/components/plus/modules/Stats/Stat';

interface GlobeProps {
  statsCards: StatProps[];
  headingGroup: HeadingGroupProps;
  customerReview: CustomerReviewProps;
}

const GlobeWithStats = ({
  statsCards,
  headingGroup,
  customerReview,
}: GlobeProps) => (
  <>
    <SectionHeader {...headingGroup} className="lg:hidden" />
    <div className="grid container row-auto md:flex-col md:grid-cols-12 gap-y-16 md:gap-y-0">
      <div className="min-h-[400px] md:col-span-5 lg:col-span-4 md:-translate-x-28 lg:-translate-x-44 xl:-translate-x-48 scale-105 md:scale-110 lg:scale-[1.7] bg-[url('https://cdn.shopify.com/b/shopify-brochure2-assets/99b83841e59332a3c25a69d85e47e110.svg')] bg-contain bg-center md:bg-left bg-no-repeat xl:mr-10"></div>
      <div className="grid gap-y-16 md:col-span-7 lg:col-span-8 md:items-center lg:items-start lg:ml-6">
        <HeadingGroup {...headingGroup} className="hidden lg:block" />
        <CardGrid numberOfColumns={3} className="grid-cols-2">
          {statsCards.map((card) => (
            <div
              className="pl-4 pt-[7px] md:pl-5 before:gradient relative before:absolute before:left-0 before:h-full before:w-1 before:rounded-sm before:bg-shade-30"
              key={card.metric}
            >
              <Stat {...card} />
            </div>
          ))}
        </CardGrid>
        <CustomerReview {...customerReview} className="hidden lg:flex" />
      </div>
    </div>
    <CustomerReview {...customerReview} className="container lg:hidden" />
  </>
);

export default GlobeWithStats;
